import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LangugageDetector from "i18next-browser-languagedetector";
import en from "./locale/en.json";
import ru from "./locale/ru.json";
import vi from "./locale/vi.json";
i18next

  .use(LangugageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "ru", "vi"],
    fallbackLng: "ru",
    resources: {
      en: {
        ...en,
      },
      ru: {
        ...ru,
      },
      vi: {
        ...vi
      }
    },
  });

