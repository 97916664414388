import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { lngs } from "../../helpers/lists";

import { ReactComponent as ArrowDownIcon } from "../../assets/icons/ArrowDownIcon.svg";
import { ReactComponent as GlobusIcon } from "../../assets/icons/GlobusIcon.svg";

export default function CustomSelect() {
  const [dropdownState, setDropdownState] = useState(false);
  const { t, i18n } = useTranslation();
  const dropdownWindowRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      dropdownWindowRef.current &&
      !dropdownWindowRef.current.contains(event.target)
    ) {
      setDropdownState(false);
    }
  };

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setDropdownState(false);
  };
  
  const handleLanguageClick = () => {
    setDropdownState(!dropdownState);
  };

  return (
    <div className="custom-select">
      <button
        className="custom-select__btn"
        tabIndex="0"
        aria-expanded="false"
        aria-controls="dropdown-1"
        onClick={handleLanguageClick}
      >
        <span>
          <GlobusIcon />
        </span>
        <span
          data-button-label
          className={`custom-select__btn-text ${dropdownState ? "active" : ""}`}
        >
          {lngs[i18n.resolvedLanguage].originName.toUpperCase()}
        </span>
        <ArrowDownIcon />
      </button>

      <ul
        className={`custom-select__dropdown list-menu ${
          dropdownState ? "active" : ""
        }`}
        ref={dropdownWindowRef}
      >
        {Object.keys(lngs).map((lang) => (
          <li
            key={lang}
            className={`custom-select__dropdown-option ${
              i18n.resolvedLanguage === lang ? "selected" : ""
            }`}
          >
            <button
              className="btn--reset link custom-select__dropdown-button"
              onClick={() => handleLanguageChange(lang)}
              disabled={i18n.resolvedLanguage === lang}
            >
              {lngs[lang].nativeName}
            </button>
          </li>
        ))}
      </ul>

      <input
        type="hidden"
        className="custom-select__input-hidden"
        value={i18n.resolvedLanguage}
      />
    </div>
  );
}
