import { Avatar, Promo, UserInfo, TableSmall } from ".";
import { ReferalsBarChart } from "./ReferalsBarChart";
import { useGetRefferalsQuery } from "../../toolkitReducers/cryptouch";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
import { TableLarge } from "./TableLarge";
import { useState } from "react";
import { RefferalsFilter } from "./RefferalsTreeFilter";
import { useTranslation } from "react-i18next";

const ReferalsPage = () => {
  const { t } = useTranslation();
  const { data } = useGetRefferalsQuery();
  const theme = useSelector(selectTheme);
  const [filter, setFilter] = useState("");

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredRefferals =
    data?.all_referrals?.length > 0 &&
    data.all_referrals.filter((ref) => ref.email.toLowerCase().includes(filter.toLowerCase()));
  return (
    <div
      className={
        theme === "light"
          ? "profile-page__body-wrapper referals-page"
          : "profile-page__body-wrapper referals-page dark-profile"
      }
    >
      <div className="profile-page__sections-wrapper medium-wrapper">
        <section className="referals-page-section">
          <div className="referals-section-user-panel">
            <Avatar />
            <UserInfo balance={data && data.bonus_crypto_balance} />
          </div>
          <Promo lvl={data && data.current_referral_level} />
        </section>
        <section className="referals-page-section">
          <TableSmall refferals={data} />
          <div className="referals-common-profit-wrapper">
            <ReferalsBarChart referals={data} />
          </div>
        </section>
        <section className="referals-page-section">
          <div className="refferals-tree-container">
            <div className="upper-part-wrapper">
              <p>{t("Путь инвестора: Вы")}</p>
              <RefferalsFilter handleFilterChange={handleFilterChange} />
            </div>

            <TableLarge refferals={filteredRefferals} />
          </div>
        </section>
      </div>
    </div>
  );
};

export { ReferalsPage };
