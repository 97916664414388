// import { refferalsLargeTableRows } from "../../helpers/lists";
import { useTranslation } from "react-i18next";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
import { useState, Fragment } from "react";
import { ReactComponent as ArrowExpandTree } from "../../assets/icons/refferals-tree-table/arrow-expand-tree.svg";
export const RefferalsSubtree = ({ subRefferalsArray, parentIdx, level }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  console.log(subRefferalsArray);
  // Toggle row expansion
  const toggleExpandRow = (idx) => {
    setExpandedRows(
      (prevExpandedRows) =>
        prevExpandedRows.includes(idx)
          ? prevExpandedRows.filter((rowIdx) => rowIdx !== idx) // Collapse row
          : [...prevExpandedRows, idx] // Expand row
    );
  };

  const { t } = useTranslation();

  return (
    subRefferalsArray.length > 0 && (
      <table className="refferals-tree-table-inner">
        <tbody>
          {subRefferalsArray.map((ref, idx) => (
            <Fragment key={`${parentIdx}-${idx}-subtree-row`}>
              <div className="user-network-filter-wrapper">
                <button>USER</button>
                <button>NETWORK</button>
              </div>
              <tr>
                <td className="cell-with-opener">
                
                  {ref.referrals && ref.referrals.length > 0 && (
                    <button onClick={() => toggleExpandRow(idx)}>
                      <ArrowExpandTree
                        className={
                          expandedRows.includes(idx)
                            ? "arrow-expand-tree expanded"
                            : "arrow-expand-tree"
                        }
                      />
                    </button>
                  )}
                  {level}
                </td>
                <td className="email-cell">{ref.email}</td>
                <td>
                  {transformDateTimeForSessionsTable(ref.registration_date)}
                </td>
                <td>
                  {transformDateTimeForSessionsTable(ref.last_deposit_date) ||
                    "-"}
                </td>
                <td>{ref.bonus_crypto_balance.btc} BTC</td>
                <td>{ref.bonus_crypto_balance.eth} ETH</td>
                <td>{parseFloat(ref.bonus_crypto_balance.usdt).toFixed(2)} USDT</td>
              </tr>

              {expandedRows.includes(idx) && ref.referrals && (
                <RefferalsSubtree
                  subRefferalsArray={ref.referrals}
                  parentIdx={`${parentIdx}-${idx}`}
                  level={level + 1} 
                />
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    )
  );
};
