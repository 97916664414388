const currencies = ["btc", "eth", "usdt"];
// languages for i18n
const lngs = {
    en: { nativeName: "English", shortName: 'EN', originName: "English" },
    ru: { nativeName: "Russian", shortName: 'RU', originName: "Русский" },
    vi: { nativeName: "Vietnamese", shortName: 'VI', originName: "tiếng Việt" },
  };


const refferalsLargeTableRows= [
"№", "Почта партнера", "Дата регистрации", "Дата деп", "Баланс BTC", "Баланс ETH", "Баланс USDT"

]

export {
    currencies, 
    lngs,
    refferalsLargeTableRows
}